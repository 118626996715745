<template>
    <v-container
        fluid
        class="fill-height"
    >
        <v-row>
            <v-col>
                <h3
                    :class="{'mobileH1':$vuetify.breakpoint.xs}"
                >
                    {{ $t('confirm-registration') }}
                </h3>
            </v-col>
        </v-row>
        <v-row
            justify="center"
        >
            <v-col
                xs="12"
                sm="4"
                class="py-0 my-0 mb-1"
            >
                <v-img
                    v-if="registrationNumberInfo.base64Image"
                    contain
                    :src="registrationNumberInfo.base64Image"
                />    
            </v-col>
        </v-row>
        <v-row
            justify="center"
        >
            <v-col
                xs="12"
                sm="2"
                class="py-0 my-0 mb-1"
            >
                <v-img
                    v-if="registrationNumberInfo.base64PlateImage"
                    contain   
                    :src="registrationNumberInfo.base64PlateImage"
                />                                      
            </v-col>
        </v-row>
        <v-row
            v-if="registrationNumberInfo"
            justify="center"
        >
            <v-col
                xs="12"
                sm="6"
                class="py-0 my-0"
            >
                <p 
                    class="py-0 my-0"
                    style="font-size:12px;"
                >
                    <b>{{ $t('order-regNumber') }} {{ registrationNumberInfo.registrationNumber }}</b>
                </p>
            </v-col>
        </v-row>            
        <v-row
            v-if="registrationNumberInfo"
            justify="center"
        >
            <v-col
                xs="12"
                sm="6"
                class="py-0 my-0"
            >
                <p 
                    class="py-0 my-0"
                    style="font-size: 12px"
                >
                    {{ $t('order-time') }} {{ registrationNumberInfo.utcRegistrationTime | luxon({ input: "formatutc", output: "formatlocal" }) }}
                </p>
            </v-col>
        </v-row>
        <v-row
            v-if="registrationNumberInfo"
            justify="center"
        >
            <v-col
                xs="12"
                sm="6"
                class="py-0 my-0"
            >
                <p 
                    class="py-0 my-0"
                    style="font-size: 12px"
                >
                    {{ $t('order-location') }}: {{ registrationNumberInfo.locationName }}
                </p>
            </v-col>
        </v-row>
        <v-row
            v-if="registrationNumberInfo"
            justify="center"
        >
            <v-col
                xs="12"
                sm="6"
                class="py-0 my-0"
            >
                <p 
                    class="py-0 my-0"
                    style="font-size: 12px"
                >
                    {{ $t('name') }}: {{ registrationNumberInfo.customerInfo.name }}
                </p>
            </v-col>
        </v-row>
        <v-row
            v-if="registrationNumberInfo"
            justify="center"
        >
            <v-col
                xs="12"
                sm="6"
                class="py-0 my-0"
            >
                <p 
                    class="py-0 my-0"
                    style="font-size: 12px"
                >
                    {{ $t('vat') }}: {{ registrationNumberInfo.customerInfo.cvrNumber }}
                </p>
            </v-col>
        </v-row>
        <v-row
            v-if="registrationNumberInfo.vehicleInfo"
            justify="center"
        >
            <v-col
                xs="12"
                sm="6"
                class="py-0 my-0"
            >
                <p 
                    class="py-0 my-0"
                    style="font-size: 12px"
                >
                    {{ $t('registration-detail-vehicle-title') }}: {{ registrationNumberInfo.vehicleInfo.brand }} 
                    <template v-if="registrationNumberInfo.vehicleInfo.model">
                        - {{ registrationNumberInfo.vehicleInfo.model }}
                    </template>
                </p>
            </v-col>
        </v-row>
        <v-row        
            class="mt-5"            
        >
            <v-col            
                class="pa-0 ma-0 ml-1 pt-10"
            >
                <v-btn                                        
                    elevation="1"
                    tile     
                    block 
                    to="/"  
                    class="btnWithRoundedCorner btnHeight42px"
                    :small="$vuetify.breakpoint.xs"
                >
                    {{ $t('go-back') }}
                </v-btn>
            </v-col>
            <v-col
                class="pa-0 ma-0 mx-1 pt-10"
            >
                <v-btn 
                    :disabled="!valid"
                    color="green"
                    class="white--text btnWithRoundedCorner btnHeight42px"
                    to="exemptionrequest"                            
                    elevation="1"
                    tile
                    block
                    :small="$vuetify.breakpoint.xs"
                >
                    {{ $t('continue') }}
                </v-btn>
            </v-col>
        </v-row>     
    </v-container>   
</template>

<script>
export default {
    name:'RegistrationInformation',
    data(){
        return {
            valid: false,
            redacted: false,
            useNoPlate: false,
            registrationNumberInfo: null
        }            
    },
    beforeMount(){
        this.registrationNumberInfo = JSON.parse(localStorage.getItem('RegistrationInformation')) || null
        if(this.registrationNumberInfo){            
            this.valid = true     
        }
        else{
            this.registrationNumberInfo = new Object()
            this.registrationNumberInfo.registrationNumber = this.$t('not-found')
            this.valid = false
        }
    }
}
</script>